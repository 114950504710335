<template>
  <PCHeader />
  <router-view></router-view>
  <PCFooter />

  <van-tabbar
    route
    v-if="showMainMenu"
    v-model="menuActive"
    active-color="#dac08f"
    inactive-color="#333"
    :placeholder="true"
    @change="onChange"
    class="main-menu-m"
  >
    <template v-for="(menuitem, mindex) in routes" v-bind:key="mindex">
      <van-tabbar-item
        v-if="menuitem.ismainmenu && menuitem.children.length <= 1"
        :name="menuitem.name"
        :to="menuitem.path"
      >
        {{ PAGELANG[menuitem.name] }}
        <template #icon="props">
          <i
            :class="
              (props.active ? 'active' : '') + ' iconfont ' + menuitem.icon
            "
          />
        </template>
      </van-tabbar-item>

      <template v-else>
        <template
          v-for="(submenu, sindex) in menuitem.children"
          v-bind:key="sindex"
        >
          <van-tabbar-item
            v-if="submenu.ismainmenu"
            :name="submenu.name"
            :to="submenu.path"
          >
            {{ PAGELANG[submenu.name] }}
            <template #icon="props">
              <i
                :class="
                  (props.active ? 'active' : '') + ' iconfont ' + submenu.icon
                "
              />
            </template>
          </van-tabbar-item>
        </template>
      </template>
    </template>
  </van-tabbar>
</template>

<script>
import PCHeader from "../components/PCHeader.vue";
import PCFooter from "../components/PCFooter.vue";

export default {
  components: {
    PCHeader,
    PCFooter,
  },
  data() {
    return {
      routes: [],
      menuActive: "home",
      showMainMenu: false,
    };
  },
  created() {
    this.PAGELANG = this.LANG.mainmenu;
    console.log(this.PAGELANG);
  },
  mounted() {
    this.onRouterChange(this.$route);
  },
  methods: {
    onChange(value) {
      console.log(value);
    },
    getMainMenus(routes, mainmenus) {
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].showmainmenu) {
          mainmenus.push(routes[i].path);
        }
        if (routes[i].children) {
          this.getMainMenus(routes[i].children, mainmenus);
        }
      }
    },
    onRouterChange(val) {
      console.log("router", val);
      let routes = this.$router.options.routes;
      this.routes = routes;

      let curpath = val.path;

      let mainmenus = Array();
      this.getMainMenus(routes, mainmenus);
      console.log("mainmenus", mainmenus);

      if (!mainmenus) return;

      this.showMainMenu = false;

      for (let i = 0; i < mainmenus.length; ++i) {
        if (mainmenus[i].indexOf(curpath) >= 0) {
          this.showMainMenu = true;
          break;
        }
      }
      console.log("showMainMenu", this.showMainMenu);
    },
  },
  watch: {
    $route(val) {
      this.onRouterChange(val);
    },
  },
};
</script>

<style scoped>
@media (min-width: 769px) {
  .main-menu-m {
    display: none;
  }
}
</style>